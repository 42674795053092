import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useCompanyConfig } from '../../fetch-data/config';
import { Helmet } from 'react-helmet';
import Spinner from '..//utils/spinner/full-page-spinner'
import ALink from '../utils/alink';
import { toDecimal } from '../utils';
import { connect } from 'react-redux';
import { cartActions } from '../store/cart';
import Error404 from '../../pages/Error404';
import { useLocation, useNavigate } from "react-router-dom";
function Order(props) {
    const { cartList, removeFromCart } = props;
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useCompanyConfig();
    const [lineItems, setLineItems] = useState();
    const [checkoutDetails, setCheckoutDetails] = useState();
    const [fullPageSpinner, setFullPageSpinner] = useState(true);
    const getCheckOutSessionIdFromURL = () => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('session_id');
    };
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                await getOrderDetails();
                setFullPageSpinner(false);
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };
        fetchInitialData();
        clearLocalStorage();
    }, []);
    const getOrderDetails = async () => {
        const response = await axios.post(
            'https://api.veckler.com/v1/pipeline/execute',
            {
                pipelineName: "StripeOrderConfirmationEmailAfterSuccessFulPayment",
                pipelineParams: [
                    { name: "checkoutSessionId", value: getCheckOutSessionIdFromURL() }
                ]
            },
            {
                headers: {
                    'x-tenant-id': data.tenantId,
                },
            }
        );
        /// this needs to check http status code ....
        if(Object.keys(response.data).length === 0){
            navigate('/pages/404')
        }
        setLineItems(response.data.lineItem);
        setCheckoutDetails(response.data.checkoutSession);
    }
    const getFormattedDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate
    }
    const clearLocalStorage = () => {
        cartList.forEach((cartItems) => {
            removeFromCart(cartItems);
        });
    };
    if (!data || fullPageSpinner) {
        return <Spinner />
    }
    return (
        <div>
            <Helmet>
                <title>{data.companyName} | order</title>
                <link rel="icon" type="image/png" href={data.favIcon} />
                <meta name="description" content={data.siteDescription} />
            </Helmet>
            <>
                {
                    !fullPageSpinner && data ? <div className="page-content pt-7  pb-10">
                        <div className="container mb-2">
                            <div className='order hide-on-print d-flex justify-content-center'>
                                <div className="order-message text-center mb-4">
                                    <div className="icon-box d-inline-flex align-items-center justify-content-center mb-3">
                                        <svg
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 50 50"
                                            className="icon-check-circle"
                                        >
                                            <g>
                                                <path
                                                    fill="none"
                                                    strokeWidth="3"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="bevel"
                                                    strokeMiterlimit="10"
                                                    d="M33.3,3.9c-2.7-1.1-5.6-1.8-8.7-1.8c-12.3,0-22.4,10-22.4,22.4c0,12.3,10,22.4,22.4,22.4c12.3,0,22.4-10,22.4-22.4c0-0.7,0-1.4-0.1-2.1"
                                                ></path>
                                                <polyline
                                                    fill="none"
                                                    strokeWidth="4"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="bevel"
                                                    strokeMiterlimit="10"
                                                    points="48,6.9 24.4,29.8 17.2,22.3"
                                                ></polyline>
                                            </g>
                                        </svg>
                                    </div>
                                    <h5 className="font-weight-bold lh-1 mb-1">Thank You!</h5>
                                    <p className="font-weight-normal lh-1 mb-3">Your order has been received</p>
                                    <div className="order-details">
                                        <p className="mb-1 font-weight-normal lh-1">Order number: {checkoutDetails.payment_intent}</p>
                                        <p className="mb-1 font-weight-normal lh-1">Date: {getFormattedDate(checkoutDetails.created)}</p>
                                        <p className="mb-1 font-weight-normal lh-1">Email: {checkoutDetails.customer_details.email}</p>
                                    </div>
                                </div>
                            </div>

                            <h2 className="title title-simple text-left pt-4 font-weight-bold text-uppercase">Order Details</h2>
                            <div className="row">
                                {lineItems.length > 0 ? (
                                    <>
                                        <div className="col-lg-8 col-md-12 pr-lg-4">
                                            <table className="shop-table cart-table">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th></th>
                                                        <th>Quantity</th>
                                                        <th>Unit Price</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lineItems.map((singleLineItem) => (
                                                        singleLineItem.productId !== 'delivery' ?
                                                            <tr key={"cart" + singleLineItem.productName}>
                                                                <td className="product-thumbnail">
                                                                    <figure>
                                                                        <ALink href={`/collections/${singleLineItem.collectionId}/products/${singleLineItem.productId}`}>
                                                                            <img
                                                                                src={singleLineItem.product_image}
                                                                                width="100"
                                                                                height="100"
                                                                                alt="product"
                                                                            />
                                                                        </ALink>
                                                                    </figure>
                                                                </td>
                                                                <td colSpan="1">
                                                                    <div className="product-name-section">
                                                                        <ALink href={`/collections/${singleLineItem.collectionId}/products/${singleLineItem.productId}`}>
                                                                            <p className="mb-2 font-weight-bolder" >{singleLineItem.productName.split(':')[1]}</p>
                                                                        </ALink>
                                                                        {singleLineItem.customisations.flatMap((customisation, index) =>
                                                                            Object.entries(customisation).map(([key, value]) => (
                                                                                <p key={`${index}-${key}`} className="mt-0 mb-0 overflow-ellipse">
                                                                                    {key} : <span className="font-weight-normal">{value}</span>
                                                                                </p>
                                                                            ))
                                                                        )}

                                                                        {/* <div className='font-weight-bold'>Quantity : <span className='font-weight-normal'>{singleLineItem.quantity}</span></div>
                                                                    <div className='font-weight-bold'>Unit Price : <span className='font-weight-normal'>{toDecimal(singleLineItem.unitPrice / 100)}</span></div> */}
                                                                    </div>
                                                                </td>
                                                                <td className="product-subtotal">
                                                                    {singleLineItem.quantity}
                                                                </td>
                                                                <td className="product-quantity">
                                                                    {toDecimal(singleLineItem.unit_amount / 100)}
                                                                </td>
                                                                <td className="product-price">
                                                                    <span className="amount">
                                                                        &euro;{toDecimal((singleLineItem.unit_amount * singleLineItem.quantity) / 100)}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            : null
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                                {/* {
                                                    shippingAddress ? (
                                                        <>
                                                            <h2 className="title title-simple text-left pt-10 mb-2">Shipping Address</h2>
                                                            <div className="main order address-info ">
                                                                <p className="address-detail pb-2">
                                                                    {Object.entries(shippingAddress).map(([key, value]) => (
                                                                        <span key={key}>
                                                                            {
                                                                                value.includes('Home Delivery') ? <strong>Delivered at</strong> : null
                                                                            }
                                                                            {value}<br /></span>
                                                                    ))}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : null
                                                } */}
                                                {/* {
                                                    promoCode ? (
                                                        <>
                                                            <h2 className="title title-simple text-left  mb-2">Promo Code Details</h2>
                                                            <div className="main order address-info ">
                                                                <p className="address-detail pb-2">
                                                                    <span key={promoCode}> <strong>Promo Code :</strong>{promoCode.id}<br /></span>
                                                                    <span key={promoCode}> <strong>Coupon Code :</strong>{promoCode.coupon.id}<br /></span>
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : null

                                                } */}
                                            </div>

                                        </div>
                                        <aside className="col-lg-4 sticky-sidebar-wrapper">
                                            <div
                                                className="sticky-sidebar"
                                                data-sticky-options="{'bottom': 20}"
                                            >
                                                <div className="summary mb-4">
                                                    <h3 className="summary-title text-left">Order Totals</h3>
                                                    <table className="shipping">
                                                        <tbody>
                                                            <tr className="summary-subtotal">
                                                                <td>
                                                                    <h4 className="summary-subtitle">Subtotal</h4>
                                                                </td>
                                                                <td>
                                                                    <p className="summary-subtotal-price">
                                                                        &euro;{toDecimal(checkoutDetails.amount_subtotal / 100)}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table className="total">
                                                        <tbody>
                                                            <tr className="summary-subtotal">
                                                                <td>
                                                                    <h4 className="summary-subtitle">Total</h4>
                                                                </td>
                                                                <td>
                                                                    <p className="summary-total-price ls-s">
                                                                        &euro;{toDecimal(checkoutDetails.amount_total / 100)}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className=" cart-actions  pt-4 justify-content-center mb-2 border-bottom">
                                                        <ALink href="/" className="btn btn-dark btn-link ">Continue Shopping</ALink>
                                                    </div>
                                                </div>
                                            </div>
                                        </aside>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div> : null
                }
            </>

        </div>
    )
}
function mapStateToProps(state) {
    return {
        cartList: state.cart.data,
    };
}
export default connect(mapStateToProps, {
    removeFromCart: cartActions.removeFromCart,
})(React.memo(Order));
