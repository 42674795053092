
import React, { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
export const fetchCompanyConfig = async () => {
    try {
        let tenantIdFile   = await axios.get('/tenant-id.json');
        const configFileURL = await axios.post(
            'https://api.veckler.com/v1/pipeline/execute',
            { 
                pipelineName: "GetTenantConfigPipeline",
                pipelineParams: []
            },
            { 
                headers: {
                    'x-tenant-id': tenantIdFile.data['x-tenant-id'],
                }
            }
        );
        const  response = await axios.get(configFileURL.data.config)
        response.data.tenantId = tenantIdFile.data['x-tenant-id'];
        return response.data;
    } catch (error) {
        console.error("Error fetching company config:", error);
        throw error;
    }
};

const CompanyConfigContext = createContext();
export const CompanyConfigProvider = ({ children }) => {
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['companyConfig'],
        queryFn: fetchCompanyConfig,
    });
    return (
        <CompanyConfigContext.Provider value={{ data, isLoading, error, refetch }}>
            {children}
        </CompanyConfigContext.Provider>
    );
};
export const useCompanyConfig = () => {
    const context = useContext(CompanyConfigContext);
    if (!context) {
        throw new Error('useCompanyConfig must be used within a CompanyConfigProvider');
    }
    return context;
};
